import auth from './modules/auth';
import dashboard from './modules/dashboard';
import regions from './modules/regions';
import franchisee from './modules/franchisee';
import petshop from './modules/petshop';
import coupon from './modules/coupon';
import faq from './modules/faq';
import leads from './modules/leads';
import contracts from './modules/contracts';
import users from './modules/user';
import orders from './modules/orders';
import financial from './modules/financial';
import logs from './modules/logs';
import config from './modules/config';
import alaytics from './modules/analytics';
import notifications from './modules/notifications';
import breeds from './modules/breed';
import videos from './modules/videos';

export default [
    ...auth,
    ...dashboard,
    ...regions,
    ...franchisee,
    ...petshop,
    ...coupon,
    ...faq,
    ...leads,
    ...contracts,
    ...users,
    ...orders,
    ...financial,
    ...logs,
    ...config,
    ...alaytics,
    ...notifications,
    ...breeds,
    ...videos,
];
